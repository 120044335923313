import React from 'react';
import { Box } from 'rebass';

import { MainLayout } from './main.layout';
import { Header } from '../components/layout/top/Header/Header.component';
import { LogoWithNav } from '../components/layout/top/LogoWithNav/LogoWithNav.component';
import { container } from '../shared-styles/container.css';
import FooterSection from '../components/layout/bottom/Footer/new_footer.component';
import { HeadPropsI } from '../meta/Head.component';
import { positionNavbar } from '../shared-styles/positioning.css';
import styled from '@emotion/styled';

const Container = styled.div`
  ${container}
`;

export const HomePageLayout: React.FC<HeadPropsI> = (props) => (
  <MainLayout fullWidth={props.fullWidth} meta={props.meta ?? undefined} og={props.og ?? undefined}>
    <Box css={positionNavbar}>
      <Header fullWidth={true} backgroundColor="backgroundGray">
        {/* <div css={container}> */}
        <LogoWithNav absPos={true} />
        {/* </div> */}
      </Header>
    </Box>

    <main>{props.children}</main>

    <FooterSection
      footerTopSection={props.footerTopSection}
      mobileBackgroundImage={'/new-images/footer-mobile.png'}
      backgroundImage={'/new-images/footer-top-section-background.png'}
    />
  </MainLayout>
);
