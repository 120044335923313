import React, { ReactElement } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { navigateTo } from '../../components/functional/Navigate';
import { HomePageLayout } from '../../layouts/HomePage.layout';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { useTranslation } from 'react-i18next';
import { VozziPrimaryButton } from '../../components/layout/_/button.component';
import { Container } from '../../layouts/main.layout';

const PageNotFound = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <HomePageLayout fullWidth={true}>
      <Container>
        <Flex
          justifyContent={{ _: 'flex-start', md: 'space-between' }}
          flexDirection={{ _: 'column', md: 'row' }}
          alignItems={{ _: 'flex-start', md: 'center' }}
          paddingTop={{ _: '60px' }}
          paddingBottom={{ _: '60px' }}
        >
          <Box>
            <Text as="h1" maxWidth={{ _: '360px' }} mb="30px">
              {t('pageNotFound_Heading')}
            </Text>
            <Text as="p" mb="30px">
              {t('pageNotFound_SubText')}
            </Text>
            <VozziPrimaryButton onClick={() => navigateTo(t('link_home'))}>{t('pageNotFound_ButtonText')}</VozziPrimaryButton>
          </Box>
          <Image src="/img/404@2x.png" />
        </Flex>
      </Container>
    </HomePageLayout>
  );
};

export default PageNotFound;
